<script>
export default {
  name: "App",
};
</script>

<template>
  <div class="main-title" id="title">
    <div class="title-container">
      <img class="logo-name" src="../assets//images/logo.png" alt="logo" />
      <h2 class="title-name2">Créateur de site Web Freelance</h2>
    </div>
  </div>
</template>

<style>
.main-title {
  height: calc(100vh - 130px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: auto;
  border-radius: 25px;
  min-width: 300px;
  max-width: 800px;
  position: relative;
}

.title-container {
  display: flex;
  flex-direction: column;
}

.logo-name {
  margin: auto;
  animation: logoIn 5s ease-in-out;
  width: 350px;
  height: 350px;
}

.title-name2 {
  font-family: "Open Sans", sans-serif;
  font-size: 42px;
  color: #bab9bf;
  letter-spacing: 2px;
  animation: fadeIn2 7s ease-in-out;
}

@keyframes logoIn {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
@keyframes fadeIn2 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
</style>