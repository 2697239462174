<script>
export default {
  name: "App",
};
</script>

<template>
  <div class="about-main" id="about">
    <h3>À Propos</h3>
    <img class="ludo-img" src="../assets/images/ludo.png" alt="ludo-img" />
    <div class="about-span">
      "Bonjour, Je suis Ludovic créateur de l'entreprise Lc Dev ayant pour but
      la conception de sites Web, d'aide au référencement naturel (SEO) ainsi
      que des refontes visuelles diverses. Passionné d’informatique depuis le
      plus jeune âge et en permanence à la recherche de nouveautés dans le
      domaine du numérique, j’ai enrichi mes compétences avec la formation de
      Développeur Web RNCP OpenClassrooms. De nature curieuse, rigoureuse et à
      l’écoute, notre collaboration sera pragmatique, simple, et humaine."
    </div>
  </div>
</template>

<style>
.about-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  min-height: 100vh;
  margin: auto;
}

.about-span {
  font-family: "Open Sans", sans-serif;
  font-size: 22px;
  color: white;
  letter-spacing: 2px;
  text-align: justify;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  min-width: 300px;
  width: 800px;
}

.ludo-img {
  width: 300px;
  height: 300px;
  min-width: 300px;
  z-index: 1;
}

@media screen and (max-width: 800px) {
  .about-span {
    width: 100%;
    letter-spacing: 0;
    text-align: center;
  }
}
</style>
