<script>
import emailjs from "@emailjs/browser";

export default {
  name: "App",
  data() {
    return {
      form: {
        username: "",
        name: "",
        email: "",
        message: "",
        completed: false,
      },
    };
  },
  methods: {
    sendEmail() {
      if (this.form.username == "" || this.form.username.length <= 2)
        return alert("Vous devez renseigner votre prénom!");
      if (this.form.name == "" || this.form.name <= 2)
        return alert("Vous devez renseigner votre nom de famille!");
      if (this.form.message == "" || this.form.message <= 2)
        return alert("Veuillez remplir le message");
      if (this.form.email == "")
        return alert("Vous devez renseigner votre email!");
      let btn = this.$refs.btn;
      btn.setAttribute("class", "submit process");
      btn.innerHTML = "Envoi en cours...";
      setTimeout(() => {
        btn.setAttribute("class", "submit submitted");
        btn.innerHTML = `
          <span class="tick">&#10004;</span>
          Envoyé !
          `;
      }, 2000);
      const service = process.env.VUE_APP_YOUR_SERVICE_ID;
      const template = process.env.VUE_APP_YOUR_TEMPLATE_ID;
      const key = process.env.VUE_APP_YOUR_PUBLIC_KEY;
      emailjs.send(service, template, this.form, key).then(
        (result) => {
          console.log("SUCCESS!", result.text);
        },
        (error) => {
          console.log("FAILED...", error.text, service, template, key);
        }
      );
      setTimeout(() => {
        this.form.completed = true;
      }, 500);
      this.resetForm();
    },
    resetForm() {
      this.form.username = "";
      this.form.name = "";
      this.form.email = "";
      this.form.message = "";
      this.form.completed = false;
    },
  },
};
</script>

<template>
  <div class="contact-main" id="contact">
    <h3>Contact</h3>
    <div class="contact-message" id="formular">
      Vous avez un projet ? Une envie ? Vous souhaitez de plus amples
      informations, ou faire une demande de devis spécifique ? Rentrez en
      contact avec moi en remplissant le formulaire ci-dessous.
        <div class="msg">
      Ou par téléphone au : <span class="number">06.50.80.70.27</span>
        </div>
    </div>
    <div class="form-container">
      <div class="w-100">
        <form @submit.prevent="sendEmail()" ref="form" id="contact-form">
          <div class="names">
            <input
              class="input"
              v-model="form.name"
              type="text"
              placeholder=" *Nom"
              name="user_name"
            />
            <input
              class="input"
              v-model="form.username"
              type="text"
              placeholder=" *Prénom"
              name="user_surname"
            />
          </div>
          <div class="email">
            <input
              class="input"
              v-model="form.email"
              type="email"
              placeholder=" *Email"
              name="user_email"
            />
          </div>
          <div class="message">
            <textarea
              class="text-area-message w-100"
              rows="6"
              v-model="form.message"
              name="message"
              placeholder="Entrez votre message ici..."
            ></textarea>
          </div>
          <button id="btn" ref="btn" class="submit" type="submit">
            Envoyer
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<style>

.msg {
  padding: 10px;
}

.number {
  font-weight: bold;
}

.submit {
  cursor: pointer;
  position: relative;
  padding: 10px 20px;
  font-size: 36px;
  width: 250px;
  border-radius: 4px;
  background: transparent;
  color: white;
  border: 2px solid;
  transition: width 0.5s;
  display: flex;
  justify-content: center;
  margin: 20px auto 20px auto;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 22px;
}

.process::before {
  content: " ";
  position: absolute;
  background-color: #361b56;
  height: 100%;
  top: 0;
  left: 0;
  width: 0%;
  animation: processing 2s;
  border-radius: 4px;
  z-index: -1;
}

.tick {
  position: absolute;
  left: 10px;
  top: 10px;
  opacity: 1;
  transition: left 2s;
}

.submitted {
  padding-left: 40px;
  animation: tick 0.6s;
  background-color: #009e79;
}

@keyframes processing {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes tick {
  0% {
    transform: scale(0.1);
  }

  75% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.contact-main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: auto;
}

.contact-message {
  display: flex;
  justify-content: center;
  margin: 20px auto 40px auto;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  color: white;
  text-align: -webkit-center;
  min-width: 300px;
  max-width: 750px;
  flex-direction: column;
  margin-bottom: 30px;
}
.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  margin: auto;
}

.names {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.email {
  display: flex;
  margin-bottom: 20px;
}

.w-100 {
  width: 100%;
  z-index: 1;
}

.text-area-message {
  text-align: start;
  border-radius: 20px;
  resize: none;
  outline: none;
  padding-left: 20px;
  padding-top: 15px;
}

.button {
  display: flex;
  justify-content: center;
  margin: 50px auto 40px auto;
  width: 200px;
  height: 50px;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  border: solid 1px grey;
  font-family: "Open Sans", sans-serif;
  font-size: 22px;
}

.button:hover {
  background-color: #c2e1f3;
}

.message {
  display: flex;
  justify-content: center;
}

input {
  outline: none;

}

.input {
  background-color: white;
  width: 100%;
  border-radius: 20px;
  height: 50px;
  padding-left: 10px;
  border-style: none;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}

.ok-message {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  text-align: center;
  color: #55bd55;
  width: 100%;
}

@media screen and (max-width: 991px) {
  .form-container {
    width: 82%;
  }

  .input {
    padding: 0;
  }
  .names {
    flex-direction: column;
    width: 100%;
  }
}
</style>
