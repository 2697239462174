<script>
import Particle from "./../Particle";

export default {
  name: "App",
  data() {
    return {
      context: null,
      oldTimeStamp: 0,
      particles: [],
    };
  },
  mounted() {
    window.addEventListener("resize", () => this.resize());
    this.context = this.$refs.canvas.getContext("2d");
    this.resize();
    this.init();
    this.loop(0);
  },
  methods: {
    resize() {
      this.$refs.canvas.width = window.innerWidth;
      this.$refs.canvas.height = window.innerHeight;
    },
    init() {
      for (let i = 0; i < 300; i++) {
        this.particles.push(new Particle(this.$refs.canvas, this.context));
      }
    },
    loop(timestamp) {
      const delta = Math.abs(timestamp - this.oldTimeStamp) / 1000;
      this.oldTimeStamp = timestamp;

      this.context.clearRect(
        0,
        0,
        this.$refs.canvas.width,
        this.$refs.canvas.height
      );
      for (const particle of this.particles) {
        particle.draw(delta);
      }

      requestAnimationFrame((t) => this.loop(t));
    },
  },
};
</script>

<template>
  <div>
    <canvas ref="canvas"></canvas>
  </div>
</template>

<style scoped>
div {
  overflow: hidden;
}
canvas {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
}
</style>
