<script>
import { VueperSlides, VueperSlide } from "vueperslides";
export default {
  components: { VueperSlides, VueperSlide },
  name: "SliderPhoto",
  data() {
    return {
      slides: [
      {
          src: "logo_clementine_porte_white.png",
          alt: "logo_clementine_porte",
          url: "https://www.clementineporte.fr/",
        },
        {
          src: "logo_cape_noire.png",
          alt: "logo_cape_noire",
          url: "https://www.lacapenoire.com/",
        },
        {
          src: "pirate_logo.png",
          alt: "asso_pirate_marquay",
          url: "http://www.assopirate.fr/",
        },
        {
          src: "coach_white.png",
          alt: "delphine_coach_sportive",
          url: "https://www.coach-delphine.com/",
        },
        {
          src: "amea_logo_white.png",
          alt: "ella_amea_logo_",
          url: "https://www.ella-amea.com/",
        },
        {
          src: "logo_nicolas.png",
          alt: "nicolas_coaching_sportif_vtt",
          url: "https://www.coaching-sportif-vtt.fr/",
        },
        {
          src: "lescapade_logo.png",
          alt: "lescapade_a_lascaux",
          url: "https://www.lescapadealascaux.com/",
        },
      ],
    };
  },
  methods: {
    getImg(img) {
      return require(`../assets/images/${img}`);
    },
  },
};
</script>
<template>
  <section class="refs" id="ref">
    <h3>Projets réalisés</h3>
    <div class="container-slider">
      <vueper-slides
        class="no-shadow"
        bullets-outside
        arrows-outside
      >
        <vueper-slide
          v-for="(slide, i) in slides"
          :link="slide.url"
          :image="getImg(slide.src)"
          :key="i"
        >
        </vueper-slide>
      </vueper-slides>
    </div>
  </section>
</template>

<style src="../../node_modules/vueperslides/dist/vueperslides.css"></style>
<style>

.refs {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container-slider {
  max-width: 800px;
  width: 50%;
  margin: auto;
}
.vueperslides__arrow,
.vueperslides__bullet .default {
  color: white;
  z-index: 1;
}
.vueperslides__bullets button, .vueperslides__bullet {
  z-index: 1;
}
.vueperslide{
  white-space: normal;
  background-size: contain;
  flex-shrink: 0;
  display: block;
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
}
</style>
