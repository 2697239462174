<script>
export default {
  name: "App",
  metaInfo: {
    title: "Lc Dev",
  },
};
</script>

<template>
  <div class="header-2" id="header">
    <a href="#title">
      <img
        class="logo-header"
        src="../assets/images/logo.png"
        alt="logo-lc-dev"
      />
    </a>
      <input type="checkbox" id="menu-toggle" />
      <label id="menu-button" class="menu-button" for="menu-toggle">
        <span class="line"></span>
        <span class="line"></span>
        <span class="line"></span>
      </label>
    <ul>
      <li>
        <a href="#title" class="header-span">Accueil</a>
      </li>
      <li>
        <a href="#about" class="header-span">À propos</a>
      </li>
      <li>
        <a href="#services" class="header-span">Offres</a>
      </li>
      <li>
        <a href="#ref" class="header-span">Projets</a>
      </li>
      <li>
        <a href="#contact" class="header-span">Contact</a>
      </li>
    </ul>
  </div>
</template>

<style>
.logo-header {
  margin-left: 90px;
  height: 90px;
  width: 90px;
  cursor: pointer;
}

.header-2 {
  background-color: #0c1630;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 130px;
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 2;
}

ul {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin-right: 90px;
  gap: 50px;
}

li {
  color: white;
}

/* test bouton */

[type="checkbox"] {
  display: none;
}

.menu-button {
  display: none;
  right: 0px;
  z-index: 100;
  width: 30px;
  height: 25px;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

@media screen and (max-width: 550px) {
  .menu-button {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }

  li {
    display: none;
  }

  [type="checkbox"]:checked ~ ul li {
    display: block;
  }
}

.line {
  height: 3px;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.5s ease;
}

#menu-toggle:checked ~ .menu-button {
  transform: rotate(180deg);
  transition: transform 0.2s ease;
}

#menu-toggle:checked ~ #menu-button .line:nth-child(1) {
  transform: translateY(13px) rotate(45deg);
}

#menu-toggle:checked ~ #menu-button .line:nth-child(2) {
  display: none;
}

#menu-toggle:checked ~ #menu-button .line:nth-child(3) {
  transform:  translateY(-8px) rotate(-45deg);
}

#menu-toggle:checked ~ #menu-button ~ #site-menu {
  animation: menu-open 2s ease both;
}

#menu-toggle:checked ~ #menu-button {
  animation: menu-txt-open 2.5s ease both;
}

/* fin test   */

.header-span {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: bold;
  transition: 1s;
}

.header-span:hover {
  filter: drop-shadow(0px 0px 15px #1b3a90) drop-shadow(0px 0px 15px #332c9f)
    drop-shadow(0px 0px 30px rgba(95, 114, 147, 0.8));
}

@media screen and (max-width: 815px) {
  .logo-header {
    margin: auto;
    height: 150px;
    width: 150px;
  }

  .header-2 {
    flex-direction: column;
    height: fit-content;
  }

  ul {
    margin: auto auto 10px auto;
    gap: 40px;
  }
}

@media screen and (max-width: 548px) {
  ul {
    flex-wrap: wrap;
    flex-direction: column;
    gap: 10px;
  }
}
</style>
