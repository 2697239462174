<script>
export default {
  name: "App",
};
</script>

<template>
  <div class="main-services" id="services">
    <h3>Mes offres</h3>
    <!-- <h3 class="h-services-span">La rentrée est la! Venez l'entamer au meilleur prix! Profitez des réductions de Septembre</h3>
      <h3 class="h-services-span">Et trouvez la meilleure offre qui vous correspond.</h3> -->
    <div class="my-services">
      <div class="board-1 first">
        <div class="services-menu">
          <div class="img-h">
            <img
              class="services-img"
              src="../assets/images/balise3.png"
              alt="vitrine"
            />
            <h3 class="h-services-span">Audit intégral</h3>
          </div>
          <span class="price"><span class="priceSpan">À partir de<span class="€Price">20€</span>HT</span></span>
          <span class="services-span">- Comprenez mieux votre site internet (fonctionnement général)
          </span>
          <span class="services-span">- Audit de performance du site</span>
          <span class="services-span">- Utilisation d'outils d'analyse</span>
          <span class="services-span">- Analyse des résultats par divers logiciels</span>
          <span class="services-span">- Proposition de solutions adéquates</span>
          <span class="services-span">- Support 7/7j</span>
        </div>
        <a href="#contact" class="clickable shimmer un">
          Je réserve!</a
        >
      </div>
      <div class="board-1 second">
        <div class="services-menu">
          <div class="img-h">
            <img
              class="services-img"
              src="../assets/images/balise1.png"
              alt="seo"
            />
            <h3 class="h-services-span">Site Vitrine</h3>
          </div>
          <span class="price"><span class="priceSpan">À partir de<span class="€Price">700€</span>HT</span></span>
          <span class="services-span">- Conseil(s) de début de projet</span>
          <span class="services-span">- Orientation du projet en fonction du / des besoin(s)</span>
          <span class="services-span">- Intégration de / des page(s)</span>
          <span class="services-span">- Rédaction du contenu</span>
          <span class="services-span">- Gestion de l'hebergeur</span>
          <span class="services-span">- Mise à jour du site</span>
          <span class="services-span">- Support 7/7j</span>
          <span class="services-span remise"></span>
          <span class="services-span remise"></span>
          <span class="services-span remise"></span>
          <span class="services-span remise"></span>
          <span class="services-span remise">Audit offert pour l'achat de cette formule</span>
        </div>
        <a href="#contact" class="clickable shimmer deux">
          Je réserve!</a
        >
      </div>
      <div class="board-1 third">
        <div class="services-menu">
          <div class="img-h">
            <img
              class="services-img"
              src="../assets/images/balise2.png"
              alt="vitrine"
            />
            <h3 class="h-services-span">Refonte graphique</h3>
          </div>
          <span class="price"><span class="priceSpan">À partir de<span class="€Price">700€</span>HT</span></span>
          <span class="services-span">- Refonte intégrale de votre site web (Wix / Wordpress /Prestashop/ ou projet personnel / etc..)</span>
          <span class="services-span">- Implémentation de fonctionnalités visuelles</span>
          <span class="services-span">- Validation de contraste (couleur de fond / police / etc ..)</span>
          <span class="services-span">- Possibilité de faire une maquette de votre projet</span>
          <span class="services-span">- Suivi / Maintenance du site</span>
          <span class="services-span">- Support 7/7j</span>
          <span class="services-span remise">Audit offert pour l'achat de cette formule</span>
        </div>
        <a href="#contact" class="clickable shimmer trois">
          Je réserve!
        </a>
      </div>
      <div class="board-1 fourth">
        <div class="services-menu">
          <div class="img-h">
            <img
              class="services-img"
              src="../assets/images/balise4.png"
              alt="vitrine"
            />
            <h3 class="h-services-span">Référencement (SEO)</h3>
          </div>
          <span class="price"><span class="priceSpan">À partir de<span class="€Price">500€</span>HT</span></span>
          <span class="services-span">- Optimisation du site</span>
          <span class="services-span">- Choix des keywords </span>
          <span class="services-span">- Utilisation de la meta description</span>
          <span class="services-span">- Optimisation du référencement naturel</span>
          <span class="services-span">- Monter dans le classement des recherches Google</span>
          <span class="services-span">- Suivi du site pendant 1 an</span>
          <span class="services-span">- Support 7/7j</span>
          <span class="services-span remise"></span>
          <span class="services-span remise"></span>
          <span class="services-span remise"></span>
          <span class="services-span remise"></span>
          <span class="services-span remise">Audit offert pour l'achat de cette formule</span>
        </div>
        <a href="#contact" class="clickable shimmer quatre">
          Je réserve!
        </a>
      </div>
    </div>
  </div>
</template>

<style>

.remise {
  text-align: center;
  font-style: italic;
}

.clickable {
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0574fc', endColorstr='#000000',GradientType=1 );
  width: 270px;
  height: 50px;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  line-height: 44px;
  text-align: center;
  border: 1px solid #fdfdfd;
  border-radius: 10px;
  margin: 45px 0 0 0;
}
.shimmer {
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin: 24px auto;
}

.shimmer:after {
  animation: animate_shimmer 6s ease-in-out infinite;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  top: -122%;
  width: 200%;
  height: 340%;
  opacity: 0;
  transform: rotate(-345deg);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 31%,
    rgba(255, 255, 255, 0) 32%,
    rgba(255, 255, 255, 1) 49%,
    rgba(255, 255, 255, 0.96) 50%,
    rgba(255, 255, 255, 0) 73%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
  transition: all 0.5s ease-out;
}

@keyframes animate_shimmer {
  10% {
    opacity: 0.6;
    left: -200%;
  }

  100% {
    opacity: 0;
    left: 200%;
  }
}

.main-services {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  min-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.my-services {
  display: flex;
  gap: 20px;
  justify-content: center;
  width: 100%;
  min-width: 300px;
  flex-wrap: wrap;
}

.board-1 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 20%;
  border-radius: 20px;
  align-items: center;
  flex-wrap: wrap;
  min-width: 300px;
  min-height: 560px;
  justify-content: space-between;
}

.first {
  background: linear-gradient(135deg, rgb(12 6 40), rgb(10 10 104));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}

.second {
  background: linear-gradient(
    135deg,
    rgba(19, 45, 88, 100),
    rgba(64, 135, 219, 100)
  );
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}

.third {
  background: linear-gradient(
    135deg,
    rgba(21, 74, 99, 100),
    rgba(118, 191, 240, 100)
  );
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}

.fourth {
  background: linear-gradient(135deg, rgb(2 74 57), rgb(91 205 200));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}

.price {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  height: 64px;
}

.priceSpan {
  display: flex;
  justify-content: center;
  height: 50px;
  width: 220px;
  border: solid 2px;
  border-image: linear-gradient(to right, rgb(200, 200, 203), rgb(80, 80, 82)) 1;
  text-align: center;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  color: white;
}

.€Price {
  font-family: "Open Sans", sans-serif;
  font-size: 30px;
  color: white;
  padding: 5px;
}

.img-h {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-top: 10px;
  align-items: center;
}

.services-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  color: white;
  font-size: 22px;
}

.h-services-span {
  font-family: "Open Sans", sans-serif;
  color: white;
  font-size: 24px;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.services-img {
  height: 60px;
  width: 70px;
}

.services-span {
  font-family: "Open Sans", sans-serif;
  color: white;
  font-size: 18px;
  padding: 15px 15px 0 15px;
  word-break: break-word;
}
</style>
