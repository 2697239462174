<script>
export default {
  name: "App",
};
</script>

<template>
  <div class="footer-main">
    <div class="footer" id="footer">
      <a target="_blank" href="https://facebook.com/ludo.lc.52/">
        <img
          class="images-logo-footer-fb"
          src="../assets/images/facebook_white.png"
          alt="facebook"
        />
      </a>

      <a target="_blank" href="https://www.instagram.com/ludo_lcdev/">
        <img
          class="images-logo-footer-insta"
          src="../assets/images/instagram_white.png"
          alt="instagram"
        />
      </a>

      <a target="_blank" href="https://linkedin.com/in/ludoviclc1/">
        <img
          class="images-logo-footer-linkedin"
          src="../assets/images/linkedin_white.png"
          alt="linkedin"
        />
      </a>

      <a target="_blank" href="https://github.com/LudoLc/">
        <img
          class="images-logo-footer-github"
          src="../assets/images/github_white.png"
          alt="github"
        />
      </a>
    </div>
    <div class="copyright">LC Dev © - 2023 / SIRET : 91779291300019</div>

  </div>
</template>

<style>
.footer-main {
  z-index: 1;
  background-color: #18213a;
  position: relative;
}
.footer {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  z-index: 1;
}

.images-logo-footer-insta:hover {
  background-image: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  outline-color: #a02d76;
  border-radius: 14px;
  cursor: pointer;
}

.images-logo-footer-linkedin:hover {
  background-color: #0072b1;
  outline-color: #0072b1;
  border-radius: 14px;
}

.images-logo-footer-fb:hover {
  background-color: #4267b2;
  outline-color: #4267b2;
  border-radius: 25px;
}

.images-logo-footer-github:hover {
  background-color: black;
  outline-color: black;
  border-radius: 25px;
}

.images-logo-footer-fb {
  width: 50px;
  height: 50px;
  border-radius: 14px;
  cursor: pointer;
  -webkit-box-reflect: below 2px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(30%, transparent), to(rgba(255, 255, 255, 0.34)));
}

.images-logo-footer-insta {
  width: 50px;
  height: 50px;
  border-radius: 14px;
  cursor: pointer;
  -webkit-box-reflect: below 2px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(30%, transparent), to(rgba(255, 255, 255, 0.34)));
}

.images-logo-footer-linkedin {
  width: 50px;
  height: 50px;
  border-radius: 14px;
  cursor: pointer;
  -webkit-box-reflect: below 2px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(30%, transparent), to(rgba(255, 255, 255, 0.34)));
}

.images-logo-footer-github {
  width: 50px;
  height: 50px;
  border-radius: 14px;
  cursor: pointer;
  -webkit-box-reflect: below 2px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(30%, transparent), to(rgba(255, 255, 255, 0.34)));
}

.copyright {
  height: 50px;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  color: white;
  justify-content: center;
  z-index: 1;
  margin-top: 30px;
}
</style>