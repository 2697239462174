const randomClamp = (min, max) => Math.random() * (max - min) + min;

export default class Particle {
  constructor(canvas, context) {
    this.canvas = canvas;
    this.context = context;

    this.depth = randomClamp(2, 1);
    this.x = randomClamp(0, this.canvas.width);
    this.y = randomClamp(0, this.canvas.height);
    this.vx = randomClamp(4, -4) / (this.depth * 0.16);
    this.vy = randomClamp(15, 10) / (this.depth * 0.16);
    this.size = this.depth;
  }

  draw(delta) {
    this.x += this.vx * delta;
    if (this.x >= this.canvas.width) this.x %= this.canvas.width;
    if (this.x <= 0) this.x = (this.x % this.canvas.width) + this.canvas.width;
    this.y -= this.vy * delta;
    if (this.y >= this.canvas.height) this.y %= this.canvas.height;
    if (this.y <= 0)
      this.y = (this.y % this.canvas.height) + this.canvas.height;

    this.context.save();
    this.context.beginPath();
    this.context.globalAlpha = this.depth * 0.16;
    this.context.fillStyle = "#FFF";
    this.context.arc(this.x, this.y, this.size, 0, 2 * Math.PI);
    this.context.fill();
    this.context.closePath();
    this.context.restore();
  }
}
