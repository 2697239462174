<script>
import Header from "./components/Header.vue";
import About from "./components/About.vue";
import Services from "./components/Services.vue";
import Contact from "./components/Contact.vue";
import Footer from "./components/Footer.vue";
import SliderPhoto from "./components/SliderPhoto.vue";
import TitleName from "./components/TitleName.vue";
import Particles from "./components/Particles.vue";



export default {
  components: {
    Header,
    TitleName,
    About,
    Services,
    Contact,
    Footer,
    SliderPhoto,
    Particles,
  },
};
</script>


<template>
  <div class="main">
    <Particles></Particles>
    <Header></Header>
    <TitleName></TitleName>
    <About></About>
    <Services></Services>
    <SliderPhoto></SliderPhoto>
    <Contact></Contact>
    <Footer></Footer>
  </div>
</template>

<style>
*{
  box-sizing: border-box;
}
html,
body {
  scroll-behavior: smooth;
  position: relative;
  min-height: 100vh;
  margin: auto;
}

.main {
  background-color: rgb(6, 6, 28);
}

a {
  text-decoration: none;
  cursor: pointer;
  color: white;
}

h2 {
  color: white;
  font-family: "Open Sans", sans-serif;
  font-size: 36px;
  margin: 0;
}

h3 {
  padding-bottom: 40px;
  padding-top: 90px;
  color: white;
  display: flex;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
  font-size: 36px;
}

@media screen and (max-width: 1276px) {
  .main-services {
    margin-top: 200px;
  }

  h3 {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1275px) {
  .board-1 {
    width: 40%;
  }
}

@media screen and (max-width: 800px) {
  .main {
    display: flex;
    flex-direction: column;
  }
}
</style>
